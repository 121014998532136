<template>
  <div class="zoom-ar-widget-window">
    <div class="zoom-ar-widget-window-close" @click="fireModalHide"></div>
    <div class="zoom-ar-widget-window-texts">
      <h2 class="zoom-ar-widget-window-texts__title">ZOBACZ U SIEBIE!</h2>
      <p class="zoom-ar-widget-window-texts__description">
        Teraz możesz postawić nasz mebel w swoim domu, jeszcze przed jego zakupem.
      </p>
      <p class="zoom-ar-widget-window-texts__ar">
        Aby zobaczyć model w technologii AR wybierz kolor i naciśnij przycisk ZOBACZ U SIEBIE.
      </p>
      <div class="zoom-ar-widget-window-viewer__colors zoom-ar-widget-window-colors" v-if="manifest.colors.length > 1">
        <div class="zoom-ar-widget-window-colors__item" v-for="(color, key) in manifest.colors" :key="key">
          <div class="zoom-ar-widget-window-colors__item__dot" :style="{background: color.value}" @click="changeModel(color.filename)"></div>
        </div>
      </div>
    </div>
    <div class="zoom-ar-widget-window-viewer">
      <h3 class="zoom-ar-widget-window-viewer__title" v-text="manifest.name"></h3>
      <span class="zoom-ar-widget-window-viewer__description">
        Klinij na obiekt 3D, by go obrócić
      </span>
      <model-viewer :src="active" auto-rotate ar ar-scale="auto" camera-controls :alt="manifest.name"
                    :ios-src="active_ios" magic-leap unstable-webxr interaction-prompt-style="basic" interaction-prompt-threshold="100">
        <button class="zoom-ar-widget-window-viewer__button" slot="ar-button">ZOBACZ U SIEBIE!</button>
      </model-viewer>
    </div>
  </div>
</template>

<script>
  import '@google/model-viewer';
  export default {
    name: "ViewerComponent",
    props: {
      manifest: {
        type: Object,
        required: true
      }
    },
    data: function () {
      return {
        active: '',
        active_ios: ''
      }
    },
    methods: {
      changeModel(filename){
        this.active = window.modelBaseUrl + filename + '/' + filename+ '.glb';
        this.active_ios = window.modelBaseUrl + filename + '/' + filename+ '.usdz';
      },
      fireModalHide(){
        window.zoomVue.$emit('modal-hide');
      }
    },
    mounted() {
      if(this.manifest){
        this.changeModel(this.manifest.colors[0].filename);
      }
    }
  }
</script>

<style lang="scss" scoped>
.zoom-ar-widget-window {
  box-sizing: border-box;
  width: 100%;
  max-width: 900px;
  height: 100%;
  max-height: 500px;
  overflow-y: auto;
  display: flex;
  position: absolute;
  z-index: 10001;
  background: white;

  &-texts {
    box-sizing: border-box;
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px 0 40px;
    text-align: center;

    &__title {
      margin: 0;
      font-family: 'Oswald', sans-serif;
      font-weight: 300;
      font-size: 48px;
      line-height: 64px;
      letter-spacing: 2px;
    }

    &__description {
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      margin: 20px 0;
    }

    &__ar{
      font-family: 'Poppins', sans-serif;
      font-size: 11px;
    }
  }

  &-viewer {
    box-sizing: border-box;
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 40px 0 20px;
    text-align: center;
    &__title{
      margin: 10px 0 0;
      font-family: 'Oswald', sans-serif;
      font-weight: 400;
      font-size: 24px;
    }
    &__description{
      font-family: 'Oswald', sans-serif;
      font-weight: 400;
      font-size: 12px;
    }
    & > model-viewer {
      width: 100%;
      height: 250px;
    }
    &__button{
      box-sizing: content-box;
      position: absolute;
      height: 32px;
      margin: 0 auto;
      left: 0;
      right: 0;
      width: 150px;
      border-radius: 16px;
      padding: 4px 12px;
      top: 10px;
      background: white;
      border: 1px solid gray;
      display: inline;
      font-family: 'Oswald', sans-serif;
      font-weight: 300;
      font-size: 16px;
    }
  }

  &-colors{
    display: flex;
    flex-wrap: wrap;
    padding: 20px 20px;
    &__item{
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 12.5%;
      margin: 10px 0;
      -webkit-tap-highlight-color: transparent;
      &__dot{
        width: 25px;
        height: 25px;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
  &-badges {
    box-sizing: border-box;
    display: flex;
    justify-content: space-evenly;
    padding-right: 1%;
    &__item {
      & > img {
        height: 75px;
      }
    }
  }
  &-close {
    padding: 4px;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 16px;
    height: 16px;
    opacity: 0.3;
    cursor: pointer;
  }
  &-close:hover {
    opacity: 1;
  }
  &-close:before, &-close:after {
    position: absolute;
    right: 11px;
    content: ' ';
    height: 16px;
    width: 2px;
    background-color: #333;
  }
  &-close:before {
    transform: rotate(45deg);
  }
  &-close:after {
    transform: rotate(-45deg);
  }
}
@media (max-width: 900px) {
  .zoom-ar-widget-window {
    width: calc(100% - 20px);
    height: auto;
    max-height: none;
    flex-direction: column;
    &-texts {
      width: 100%;
      padding: 0 20px 0 20px;
      &__title {
        margin-top: 20px;
        text-align: center;
        font-family: 'Oswald', sans-serif;
        font-weight: 300;
        font-size: 32px;
        letter-spacing: 2px;
      }

      &__description {
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
      }
    }

    &-viewer {
      width: 100%;
      padding: 0 20px 0 20px;
      &__title{
        margin: 10px 0;
        text-align: center;
        font-family: 'Oswald', sans-serif;
        font-weight: 400;
        font-size: 24px;
      }
      & > model-viewer {
        width: 100%;
        height: 250px;
      }
    }

    &-colors{
      margin: auto 0;
      padding: 20px 0;
      &__item{
        &__dot{
          width: 30px;
          height: 30px;
        }
      }
    }

    &-close {
      right: 4px;
      top: 4px;
    }
    &-close:before, &-close:after {
      right: 11px;
    }
  }
}
@media (max-width: 540px) {
  .zoom-ar-widget-window{
    &-badges {
      &__item {
        & > img {
          height: 60px;
        }
      }
    }
  }
}

</style>
