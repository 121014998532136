const element = document.createElement('div');
element.id = 'zoom-ar-widget';
document.body.appendChild(element);

import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'

window.modelBaseUrl = '/ar/';

window.axios = axios.create({
  baseURL: window.modelBaseUrl,
});
import '@webcomponents/webcomponentsjs/webcomponents-loader.js'
import 'intersection-observer/intersection-observer.js'
import 'resize-observer-polyfill/dist/ResizeObserver.js'
import 'fullscreen-polyfill/dist/fullscreen.polyfill.js'
import 'focus-visible/dist/focus-visible.js'
import urlParse from 'url-parse';
window.urlParse = urlParse;

Vue.config.productionTip = false;

window.zoomVue = new Vue({
  'render': h => h(App)
});
window.zoomVue.$mount('#zoom-ar-widget');
