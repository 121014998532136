<template>
  <transition appear name="fade" v-if="loaded">
    <div class="zoom-ar-widget" v-if="modalVisible" :key="1">
      <div id="zoom-ar-widget" class="zoom-ar-widget__background" @click="modalHide"></div>
      <viewer-component :manifest="manifest"></viewer-component>
    </div>
    <div class="zoom-ar-widget__button" v-else @click="modalShow" :key="2">
      <img src="./assets/soltix-ar-badge.svg" alt="Zobacz u siebie!">
    </div>
  </transition>
</template>

<script>
  import ViewerComponent from "./components/ViewerComponent";
  export default {
    components: {
      ViewerComponent
    },
    data: function () {
      return {
        modalVisible: false,
        manifest: {
          colors: []
        },
        loaded: false
      }
    },
    methods: {
      modalHide(){
        this.modalVisible = false;
      },
      modalShow(){
        if(typeof ga === "function"){
          // eslint-disable-next-line no-undef
          ga('send', 'event', 'Widget', 'AR', 'Klikniecie');
        }
        this.modalVisible = true;
      },
      getIdentifierFromUrl(){
        const pathname = window.urlParse(window.location.href).pathname;
        let chunks = pathname.split('/');
        this.getManifest(chunks[1]);
      },
      getManifest(name){
        if (name === undefined || name === '') {
          name = window.location.href+'ar';
        }else{
          name = window.location.origin+'/ar/'+name;
        }

        window.axios.get(name + '/manifest.json')
        .then((response) => {
          this.manifest = response.data;
          this.loaded = true;
        })
        .catch(() => {
          this.loaded = false;
        })
      }
    },
    mounted() {
      this.getIdentifierFromUrl();
      window.zoomVue.$on('modal-hide', () => {
        this.modalHide();
      });
    }
  }
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Oswald:300,400|Poppins&display=swap');
.zoom-ar-widget {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;

  &__background{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9000;
    background: rgba(0, 0, 0, .4);
  }

  &__button {
    box-sizing: border-box;
    height: 100px;
    width: 420px;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 9999;
    right: 25px;
    bottom: 10px;
    padding: 5px;
    cursor: pointer;
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    font-size: 28px;
    line-height: 32px;
    background: transparent;
    & > span {
      text-align: center;
      width: 50%;
    }
    & > img {
      max-width: 420px;
      height: 48px;
      //width: 120px;
      //position: absolute;
      //right: 0;
      //bottom: 10px;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
@media (max-width: 540px) {
  .zoom-ar-widget {
    &__button {
      width: 100%;
      right: 0;
      & > img {
        max-width: 100%;
        width: auto;
        height: auto;
      }
    }
  }
}

</style>
